import React, { SVGAttributes } from "react"

type ArrowProps = {
  color?: string
} & SVGAttributes<SVGElement>

const ArrowSVG: React.FC<ArrowProps> = ({ color = "#fff", ...props }) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.23642 7.6364C8.58789 7.28492 8.58789 6.71508 8.23642 6.3636L2.50886 0.636039C2.15738 0.284567 1.58753 0.284567 1.23606 0.636039C0.884591 0.987511 0.884591 1.55736 1.23606 1.90883L6.32723 7L1.23606 12.0912C0.884591 12.4426 0.884591 13.0125 1.23606 13.364C1.58753 13.7154 2.15738 13.7154 2.50886 13.364L8.23642 7.6364ZM6.40002 7.9H7.60002V6.1H6.40002V7.9Z"
      fill={color}
    />
  </svg>
)

export default ArrowSVG
