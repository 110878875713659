import React, { useState, useEffect } from "react"

import { blackTheme } from "@ecom/ui/styles/themes/black"
import { redTheme } from "@ecom/ui/styles/themes/red"

import BaseLayout, { BaseLayoutProps } from "./base"

import { getIDBValue } from "../../utils/idbUtils"

export default function SecondaryPageLayout({
  children,
  title,
  description,
}: Omit<BaseLayoutProps, "theme">) {
  const [theme, setTheme] = useState<any>()

  useEffect(() => {
    getIDBValue("theme").then((style) => {
      if (style === "black") {
        setTheme(blackTheme)
        document.documentElement.style.setProperty("--primary-color", "#0A0A0A")
      } else {
        setTheme(redTheme)
        document.documentElement.style.setProperty("--primary-color", "#ff4e50")
      }
    })
  }, [])

  if (!theme) {
    return null
  }

  return (
    <BaseLayout title={title} description={description} theme={theme} noIndex>
      {children}
    </BaseLayout>
  )
}
