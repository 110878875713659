import React, { HTMLAttributes } from "react"
import clsx from "clsx"

import * as styles from "./CircleContainer.module.scss"

const CircleContainer = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx(styles.container, className)} {...props}>
    {children}
  </div>
)

export default CircleContainer
