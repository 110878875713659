// extracted by mini-css-extract-plugin
export var btn = "ChoiceRedesign-module--btn--sdpqJ";
export var btnBank = "ChoiceRedesign-module--btnBank--ECnNN";
export var btnGroup = "ChoiceRedesign-module--btnGroup--YNRnR";
export var container = "ChoiceRedesign-module--container--nchn7";
export var display = "ChoiceRedesign-module--display--j+QWZ";
export var head = "ChoiceRedesign-module--head--+KW4o";
export var img = "ChoiceRedesign-module--img--M8nc-";
export var logo_background = "ChoiceRedesign-module--logo_background--gFqQj";
export var options = "ChoiceRedesign-module--options--4Oqho";
export var title = "ChoiceRedesign-module--title--hcTaH";