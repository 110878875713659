import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import clsx from "clsx"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import {
  pushToDataLayerSendForm,
  pushToDataLayerStepSuccess,
} from "@ecom/ui/utils/pushToDataLayerUtils"

import Button from "@material-ui/core/Button"

import { navigate } from "gatsby"
import { useLocation } from "@gatsbyjs/reach-router"
import ArrowSVG from "../../images/svg/ArrowSVG"
import CircleContainer from "../CircleContainer/CircleContainer"
import { events } from "../../helpers/events"

import * as styles from "./ChoiceRedesign.module.scss"
import { getIDBValue, setIDBValue } from "../../utils/idbUtils"
import { sendRequest } from "../../helpers/sendRequest"
import { HalvaResponse } from "../../interfaces/responseStatus"

type Choice = "delivery" | "pickup"

type Props = {
  dataLayerName?: string
}

export default function ChoiceRedesign({ dataLayerName = "longPersonalForm" }: Props) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [requestId, setRequestId] = useState("")
  const location = useLocation()

  useEffect(() => {
    getIDBValue<string>("requestId").then((value) => {
      if (value) {
        setRequestId(value)
      }
    })
  }, [])

  const onChooseOption = async (choice: Choice) => {
    try {
      setIsDisabled(true)

      const { status, apiId }: HalvaResponse = await sendRequest(
        `/v2/app/public/halva/${requestId}`,
        { choice },
        true
      )

      switch (status) {
        case "reject":
          navigate("/error-reject")
          pushToDataLayer(events.applicationSend.reject)
          return
        case "error":
          throw Error(status)
        default:
          break
      }

      setIDBValue("requestId", apiId)

      if (choice === "delivery") {
        navigate("/choose-locality/", {
          state: { thanksPage: location.state.thanksPage },
        })
      } else {
        navigate(location.state.thanksPage || "/long-form-success/")
      }
    } catch (error) {
      pushToDataLayer(events.applicationSend.error)
      pushToDataLayer(events.GAFormEvent.error)
      navigate("/error-technical/")
    } finally {
      pushToDataLayerSendForm()
      pushToDataLayerStepSuccess(dataLayerName)
    }
  }

  return (
    <>
      <h1 className={styles.head}>Телефон подтвержден</h1>
      <CircleContainer className={styles.container}>
        <StaticImage
          className={styles.img}
          alt="halva-mir"
          src="./img/halva-mir.png"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
        <div className={styles.options}>
          <h2 className={styles.title}>
            Выберите удобный
            <br />
            способ получения
          </h2>
          <div className={styles.btnGroup}>
            <Button
              className={styles.btn}
              onClick={() => onChooseOption("delivery")}
              disabled={isDisabled}
            >
              Доставить курьером
              <ArrowSVG className={styles.display} />
            </Button>
            <Button
              className={clsx(styles.btn, styles.btnBank)}
              onClick={() => onChooseOption("pickup")}
              disabled={isDisabled}
            >
              Заберу в банке
              <ArrowSVG className={styles.display} color="#FF4E50" />
            </Button>
          </div>
        </div>
        <StaticImage
          className={styles.logo_background}
          alt="logo-background"
          src="./img/logo_bg.png"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
      </CircleContainer>
    </>
  )
}
