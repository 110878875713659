import React, { useEffect, useState } from "react"

import { getIDBValue, setIDBValue } from "../../../../utils/idbUtils"

import * as styles from "./rightSection.module.scss"

type CountDownProps = {
  countDownTime?: number
}

export const CountDown = ({ countDownTime = 43200000 }: CountDownProps) => {
  const [count, setCount] = useState<number | null>(countDownTime)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadCount = async () => {
      const savedCount = await getIDBValue<number>("countDownDate")
      setCount(savedCount || countDownTime)
      setLoading(false)
    }

    loadCount()
  }, [countDownTime])

  useEffect(() => {
    if (!loading && count !== null) {
      const saveCount = async () => {
        await setIDBValue("countDownDate", count)
      }

      saveCount()
    }
  }, [count, loading])

  useEffect(() => {
    let secondsLeft: any

    if (count !== null && count > 0 && !loading) {
      secondsLeft = setInterval(() => {
        setCount((c: any): any => c - 1000)
      }, 1000)
    }

    return () => {
      clearInterval(secondsLeft)
    }
  }, [count, loading])

  const msToTime = (duration: any) => {
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
    const seconds = Math.floor((duration / 1000) % 60)

    return {
      h: hours,
      m: minutes,
      s: seconds,
    }
  }

  const timeLeft = msToTime(count)

  const [hour, setHour] = useState(timeLeft.h)
  const [minute, setMinute] = useState(timeLeft.m)
  const [second, setSecond] = useState(timeLeft.s)

  useEffect(() => {
    setHour(timeLeft.h)
    setMinute(timeLeft.m)
    setSecond(timeLeft.s)
  }, [timeLeft])

  let displayTime = ""
  if (hour > 0) {
    displayTime = `${hour <= 9 ? `0${hour}` : hour}:${minute <= 9 ? `0${minute}` : minute}:${
      second <= 9 ? `0${second}` : second
    }`
  } else {
    displayTime = `${minute <= 9 ? `0${minute}` : minute}:${second <= 9 ? `0${second}` : second}`
  }

  let loadingDisplayTime = ""
  if (hour > 0) {
    loadingDisplayTime = "--:--:--"
  } else {
    loadingDisplayTime = "--:--"
  }

  return (
    <span id="countdownBlock" className={styles.countdown}>
      {loading ? loadingDisplayTime : displayTime}
    </span>
  )
}
