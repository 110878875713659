import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

// TODO перенести в UI
export const pushToDataLayerOnce = (() => {
  const isExecuted: any = {}

  // eslint-disable-next-line func-names
  return function (name: string, data: any) {
    if (!isExecuted[name]) {
      isExecuted[name] = true
      pushToDataLayer(data)
    }
  }
})()
